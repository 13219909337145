import {
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { getInitials } from 'asap-team/asap-tools';

@Component({
  selector: 'avatar-placeholder',
  templateUrl: './avatar-placeholder.component.html',
  styleUrls: ['./avatar-placeholder.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarPlaceholderComponent {

  @Input() image: string;

  @Input() name: string;

  @Input() bgColor: string = '#fff';

  getInitials: any = getInitials;

}
