import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ROUTE } from '@consts/routes';

import type { Program } from '@core/types';

type HomeProgramsCollection = {
  items: Program[];
  limit: number;
  total: number;
  action: 'more' | 'less' | 'disabled';
};

@Component({
  selector: 'home-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramsComponent implements OnInit {

  @Input() username: string;

  @Input() programs: Program[];

  @Input() currentProgramName: string = null;

  LIMIT: number = 6;

  collection: HomeProgramsCollection;

  ngOnInit(): void {
    this.initCollection();
  }

  initCollection(): void {
    this.collection = {
      items: this.programs ? this.programs.slice(0, this.LIMIT) : [],
      limit: this.LIMIT,
      total: this.programs.length,
      action: this.programs.length <= this.LIMIT ? 'disabled' : 'more',
    };
  }

  getLink(programName: string): string {
    return this.username ? `/${this.username}/${programName}` : `/${ROUTE.name.PROGRAM}/${programName}`;
  }

  onLoad(action: HomeProgramsCollection['action']): void {
    const { items } = this.collection;

    switch (action) {
      case 'more':
        this.collection.items = [
          ...this.collection.items,
          ...this.programs.slice(items.length, items.length + this.LIMIT),
        ];
        this.checkCollectionAction();
        break;
      case 'less':
        this.initCollection();
        break;
      default:
        this.initCollection();
    }
  }

  checkCollectionAction(): void {
    const { items, total } = this.collection;

    if (items.length === total) {
      this.collection.action = 'less';
    }

    if (items.length > this.LIMIT && items.length < total) {
      this.collection.action = 'more';
    }
  }

}
