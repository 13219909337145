<section class="mortgage">
  <div class="mortgage-container text-center">
    <ng-container *ngIf="companyName; else yourCompany">
      <h2 class="mortgage-title">{{ companyName }}, <br/> a mortgage company stretching the boundaries</h2>
    </ng-container>
    <ng-template #yourCompany>
      <h2 class="mortgage-title">Your mortgage company stretching the boundaries</h2>
    </ng-template>
    <p class="mortgage-caption text--lg">
      Whether you’re a first time buyer or a seasoned homeowner, there are mortgage solutions available for you.
    </p>
    <div class="grid">
      <div class="mortgage-row grid-row">
        <div class="mortgage-col grid-col" *ngFor="let program of programs">
          <div class="mortgage-item">
            <div class="mortgage-item__icon">
              <img [src]="program.icon"/>
            </div>
            <h4 class="mortgage-item__title" [routerLink]="getProgramLink(program.name)" queryParamsHandling="preserve">
              {{ program.label }}
            </h4>
            <a class="mortgage-item__link" *ngIf="username else previewMode" [routerLink]="getProgramLink(program.name)">
              Get Started<i class="inline-svg s-16" [inlineSVG]="'symbol-defs.svg#icon-arrow-forward'"></i>
            </a>
            <ng-template #previewMode>
              <preview-button>
                <button class="mortgage-item__link">
                  Get Started<i class="inline-svg s-16" [inlineSVG]="'symbol-defs.svg#icon-arrow-forward'"></i>
                </button>
              </preview-button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
