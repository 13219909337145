import { Component, Input } from '@angular/core';

import type { HomeStepperLink, ProgramDTI } from '@core/types';

// Consts
import { DTI_DATA } from '@consts/consts';
import { ROUTE } from '@consts/routes';

@Component({
  selector: 'home-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.sass'],
})
export class ApplyComponent {

  @Input() preview: string;

  @Input() username: string;

  @Input() partner: string;

  ROUTE: any = ROUTE;

  DTI_DATA: ProgramDTI[] = DTI_DATA;

  currentTab: ProgramDTI = DTI_DATA.find((item: ProgramDTI) => item.active);

  get previewImage(): string {
    return this.preview || 'assets/images/components/home/content/biq_bg_main.jpg';
  }

  get stepperLink(): HomeStepperLink {
    return {
      link: `/${this.username}${this.ROUTE.alias.STEPPER}`,
      query_params: {
        loan_type: this.currentTab.id.toLowerCase(),
        partner: this.partner,
      },
    };
  }

  setDtiProgram(item: ProgramDTI): void {
    this.DTI_DATA = this.DTI_DATA.map((value: ProgramDTI) => {
      return {
        ...value,
        active: value.id === item.id,
      };
    });
    this.currentTab = item;
  }

}
