<section class="programs text-center">
  <p class="programs-caption text--lg">We may have a special program for you</p>
  <h2 class="programs-title">Select your industry below</h2>
  <div class="programs-container">
    <div class="programs-row flex-wrap">
      <div class="programs-col" *ngFor="let program of collection.items">
        <figure class="programs-item"
                [routerLink]="getLink(program.name)"
                routerLinkActive="active"
                queryParamsHandling="preserve">
          <div class="programs-item__icon inline-svg inline-svg--program" [inlineSVG]="program.icon"></div>
          <figcaption class="programs-item__caption">{{ program.label }}</figcaption>
        </figure>
      </div>
    </div>
    <a class="programs__show-more"
       *ngIf="collection.action !== 'disabled'"
       (click)="onLoad(collection.action)">
      Show {{ collection.action }}
    </a>
  </div>
</section>
