import { Program } from '@core/types';

export const PROGRAMS_CONFIG: Program[] = [
  {
    name: 'first_time_buyer',
    content_preview: 'assets/images/components/home/content/biq_bg_first.jpg',
    icon: 'assets/images/components/home/illustration-house-1.svg',
    label: 'First time homebuyer program',
    top_preview: 'assets/images/components/home/programs/biq_hero_first.jpg',
    top_title: 'Mortgage program for first time home buyers',
    type: 'company',
  },
  {
    name: 'second_time_buyer',
    content_preview: 'assets/images/components/home/content/biq_bg_second.jpg',
    icon: 'assets/images/components/home/illustration-house-2.svg',
    label: 'Purchasing your second home',
    top_preview: 'assets/images/components/home/programs/biq_hero_second.jpg',
    top_title: 'Mortgage program for second home',
    type: 'company',
  },
  {
    name: 'jumbo',
    content_preview: 'assets/images/components/home/content/biq_bg_jumbo.jpg',
    icon: 'assets/images/components/home/illustration-house-3.svg',
    label: 'Jumbo loan program',
    top_preview: 'assets/images/components/home/programs/biq_hero_jumbo.jpg',
    top_title: 'Mortgage loans for higher amounts',
    type: 'company',
  },
  {
    name: 'police',
    content_preview: 'assets/images/components/home/content/biq_bg_police.jpg',
    icon: 'programs/police.svg',
    label: 'Police',
    top_preview: 'assets/images/components/home/programs/biq_hero_police.jpg',
    top_title: 'Mortgage program for police officers and first responders',
    type: 'industry',
  },
  {
    name: 'firefighter',
    content_preview: 'assets/images/components/home/content/biq_bg_firefighter.jpg',
    icon: 'programs/firefighter.svg',
    label: 'Firefighter',
    top_preview: 'assets/images/components/home/programs/biq_hero_firefighter.jpg',
    top_title: 'Mortgage program for firefighters',
    type: 'industry',
  },
  {
    name: 'teacher',
    content_preview: 'assets/images/components/home/content/biq_bg_teacher.jpg',
    icon: 'programs/teacher.svg',
    label: 'Teacher',
    top_preview: 'assets/images/components/home/programs/biq_hero_teacher.jpg',
    top_title: 'Mortgage program for teachers',
    type: 'industry',
  },
  {
    name: 'healthcare',
    content_preview: 'assets/images/components/home/content/biq_bg_healthcare.jpg',
    icon: 'programs/doctor.svg',
    label: 'Healthcare',
    top_preview: 'assets/images/components/home/programs/biq_hero_healthcare.jpg',
    top_title: 'Mortgage program for doctors and nurses',
    type: 'industry',
  },
  {
    name: 'va',
    content_preview: 'assets/images/components/home/content/biq_bg_va.jpg',
    icon: 'programs/veteran.svg',
    label: 'Veterans',
    top_preview: 'assets/images/components/home/programs/biq_hero_va.jpg',
    top_title: 'Mortgage program for veterans',
    type: 'industry',
  },
  {
    name: 'engineer',
    content_preview: 'assets/images/components/home/content/biq_bg_engineer.jpg',
    icon: 'programs/engineer_new.svg',
    label: 'Engineer',
    top_preview: 'assets/images/components/home/programs/biq_hero_engineer.jpg',
    top_title: 'Mortgage loans for engineers',
    type: 'industry',
  },
  {
    name: 'business',
    content_preview: 'assets/images/components/home/content/biq_bg_business.jpg',
    icon: 'programs/business.svg',
    label: 'Business owner',
    top_preview: 'assets/images/components/home/programs/biq_hero_business.jpg',
    top_title: 'Mortgage loans for business owners',
    type: 'industry',
  },
  {
    name: 'entrepreneur',
    content_preview: 'assets/images/components/home/content/biq_bg_entrepreneur.jpg',
    icon: 'programs/entrepreneur.svg',
    label: 'Entrepreneur',
    top_preview: 'assets/images/components/home/programs/biq_hero_entrepreneur.jpg',
    top_title: 'Mortgage loans for entrepreneur',
    type: 'industry',
  },
];
