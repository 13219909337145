import { Component, Input } from '@angular/core';

import type { Program, HomeStepperLink } from '@core/types';

@Component({
  selector: 'home-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent {

  @Input() defaultPreview: string;

  @Input() program: Program;

  @Input() stepperLink: HomeStepperLink;

  get backgroundImage(): any {
    if (this.program) {
      return { 'background-image': `url('${this.program.top_preview}')` };
    }

    if (this.defaultPreview) {
      return { 'background-image': `url('${this.defaultPreview}')` };
    }

    return { 'background-image': 'url(\'assets/images/components/home/header-bg.jpg\')' };
  }

  get programTitle(): string {
    return this.program && this.program.top_title ? this.program.top_title : 'The right of passage to homeownership';
  }

}
