<div class="process">
  <div class="process-container grid">
    <h2 class="process-title">Our home purchase loan process</h2>
    <div class="grid-row">
      <div class="grid-col">
        <div class="process-item">
          <div class="process-item__icon">
            <i class="process-item__count">1</i>
            <div class="inline-svg" [inlineSVG]="'symbol-defs.svg#icon-certificate'"></div>
          </div>
          <h4 class="process-item__title">Request Certificate</h4>
          <p class="process-item__text text">See what you may qualify for with our simple online tool</p>
        </div>
      </div>
      <div class="grid-col">
        <div class="process-item">
          <div class="process-item__icon">
            <i class="process-item__count">2</i>
            <div class="inline-svg" [inlineSVG]="'symbol-defs.svg#icon-approve'"></div>
          </div>
          <h4 class="process-item__title">Get Pre-Approved</h4>
          <p class="process-item__text text">Complete our full mortgage loan application</p>
        </div>
      </div>
      <div class="grid-col">
        <div class="process-item">
          <div class="process-item__icon">
            <i class="process-item__count">3</i>
            <div class="inline-svg" [inlineSVG]="'symbol-defs.svg#icon-options'"></div>
          </div>
          <h4 class="process-item__title">Receive Options</h4>
          <p class="process-item__text text">Compare rates and terms based on your unique criteria and scenario</p>
        </div>
      </div>
      <div class="grid-col">
        <div class="process-item">
          <div class="process-item__icon">
            <i class="process-item__count">4</i>
            <div class="inline-svg" [inlineSVG]="'symbol-defs.svg#icon-offer'"></div>
          </div>
          <h4 class="process-item__title">Select Best Offer</h4>
          <p class="process-item__text text">Choose the offer that best fits your needs</p>
        </div>
      </div>
    </div>
  </div>
</div>
