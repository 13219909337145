import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseHttpService, TRACKING_PROVIDER } from 'asap-team/asap-tools';

import type { User, UserResponse } from '@core/types';

// Services
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';
import { ProgramsService } from '@core/services/programs/programs.service';

// Constants
import { EVENT_ACTIONS } from '@consts/analytics-events';

type TrackingProvider = {
  id: string;
  name: string;
};

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(
    private http: BaseHttpService,
    private trackingScriptsService: TrackingScriptsService,
    private programsService: ProgramsService,
  ) { }

  // TEMP: https://loomlogic.atlassian.net/browse/SOA-6797
  private customGTAUsers: any = [{
    email: 'hello@frielgoodmortgage.com',
    id: 'AW-871567317',
    customEvents: {
      [EVENT_ACTIONS.DOWNLOAD_BUYERIQ_CERTIFICATE]: {
        eventName: 'conversion',
        eventValue: { send_to: 'AW-871567317/sW8FCKyu_qkDENWfzJ8D' },
      },
      [EVENT_ACTIONS.VIEW_CONGRATULATIONS_PAGE]: {
        eventName: 'conversion',
        eventValue: { send_to: 'AW-871567317/SeLMCLLDxH4Q1Z_MnwM' },
      },
      [EVENT_ACTIONS.VIEW_ZIP_CODE_STEP]: {
        eventName: 'conversion',
        eventValue: { send_to: 'AW-871567317/eIYjCP3N-doBENWfzJ8D' },
      },
    },
  }];

  getUser(username: string, partner?: string): Observable<UserResponse> {
    const userQuery: string = partner ? `${username}?partner=${partner}` : `${username}`;

    return this
      .http
      .get(`v1/buyer_traffic/users/${userQuery}`)
      .pipe(
        map((response: User) => {
          return {
            user: response,
            programs: this.programsService.getPermitted(response.programs),
          };
        }),
        tap(({ user }: UserResponse) => {
          const params: TrackingProvider[] = [
            {
              id: user.settings.ga_id,
              name: TRACKING_PROVIDER.GA,
            },
            {
              id: user.settings.fb_pixel_id,
              name: TRACKING_PROVIDER.FB,
            },
          ];
          const customGTAEventsConfig: any = this.customGTAUsers.find((config: any) => config.email === user.email);

          if (customGTAEventsConfig) {
            params.push({ id: customGTAEventsConfig.id, name: 'GTA' });
          }

          if (user.settings.gtm_id && !customGTAEventsConfig) {
            params.push({ id: user.settings.gtm_id, name: TRACKING_PROVIDER.GTM });
          }

          this.trackingScriptsService.init(params, customGTAEventsConfig ? customGTAEventsConfig.customEvents : null);
        }),
      );
  }

}
