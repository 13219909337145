import type { Dictionary } from 'asap-team/asap-tools';
import type { ProgramDTI } from '@core/types';

export const CODE_STEPS: Dictionary = {
  zipCode: 'zip_code',
  whichTimeBuyer: 'which_time_buyer',
  propertyType: 'property_type',
  creditScore: 'credit_score',
  currentProperty: 'current_property',
  propertyUsed: 'property_used',
  purchasePrice: 'purchase_price',
  downPayment: 'downpayment',
  annualIncome: 'annual_income',
  monthlySpendings: 'monthly_spendings',
  employmentStatus: 'employment_status',
  fullName: 'full_name',
  email: 'email',
  phone: 'phone',
};
export const DTI_DATA: ProgramDTI[] = [
  {
    id: 'Conventional',
    title: 'DTI 28/36',
    text: 'This means that no more than 28% of your total monthly income (before taxes) can go toward housing, and no more than 36% of your monthly income can go toward your total monthly debt (including your mortgage payment).',
    active: true,
  },
  {
    id: 'FHA',
    title: 'DTI 31/43',
    text: 'This means that no more than 31% of your total monthly income (before taxes) can go toward housing, and no more than 43% of your monthly income can go toward your total monthly debt (including your mortgage payment).',
    active: false,
  },
  {
    id: 'USDA',
    title: 'DTI 29/41',
    text: 'This means that no more than 29% of your total monthly income (before taxes) can go toward housing, and no more than 41% of your monthly income can go toward your total monthly debt (including your mortgage payment).',
    active: false,
  },
  {
    id: 'VA',
    title: 'DTI 41%',
    text: 'This means that no more than 41% of your total monthly income (before taxes) can go toward housing. Possible to get approved with DTI between 41-50% with compensating factors.',
    active: false,
  },
  {
    id: 'Jumbo',
    title: 'DTI 45%',
    text: 'For qualified jumbo loans – meaning the loan has features that make it more likely that you can afford to repay it – expect to see a DTI limit of 43 to 45 percent or lower.',
    active: false,
  },
];
export const CERTIFICATE_STATE: Dictionary = {
  QUALIFIED: 'qualified',
  NOT_QUALIFIED: 'not-qualified',
  LOAN_NOT_QUALIFIED: 'loan-not-qualified',
};
