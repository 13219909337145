<section class="specialist">
  <div class="specialist-container">
    <div class="specialist-logo text-center">
      <app-logo [logo]="user?.settings?.logo"></app-logo>
    </div>
    <h2 class="specialist-title text-center">Have questions?<br/>Talk to a mortgage specialist today</h2>
    <div class="agent m-b-16">
      <div class="agent-avatar">
        <avatar-placeholder class="s-132" [image]="user?.avatar?.medium"></avatar-placeholder>
      </div>
      <div class="agent-info">
        <h4 class="agent-name">{{ user?.name }}</h4>
        <div class="agent-position m-b-4">
          <span class="agent-role">{{ user?.job_title || user?.role }} • </span>
          <span class="agent-license">NMLS {{ user?.license_number }}</span>
        </div>
        <div class="agent-position m-b-16" *ngIf="user?.company_license_number">
          <span class="agent-company">{{ user?.company_name }} • </span>
          <span class="agent-license">NMLS {{ user?.company_license_number }}</span>
        </div>
        <div class="agent-contacts">
          <a class="agent-contacts__item agent-contacts__item--phone text-overflow"
             [href]="'tel:' + user?.phone"
             [title]="user?.phone"
             (click)="track(EVENT_ACTIONS.CLICK_PHONE_LINK)">
            {{ user?.phone | phone }}
          </a>
          <a class="agent-contacts__item agent-contacts__item--email text-overflow"
             [href]="'mailto:' + user?.email"
             [title]="user?.email"
             (click)="track(EVENT_ACTIONS.CLICK_EMAIL_LINK)">
            {{ user?.email }}
          </a>
        </div>
        <div class="socials__list" *ngIf="user?.socials">
          <a class="socials__list--link"
             *ngIf="user?.socials?.facebook_url"
             [href]="user?.socials?.facebook_url"
             target="_blank">
            <img src="assets/icons/socials/facebook.svg" alt="facebook-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="user?.socials?.instagram_url"
             [href]="user?.socials?.instagram_url"
             target="_blank">
            <img src="assets/icons/socials/instagram.svg" alt="instagram-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="user?.socials?.linkedin_url"
             [href]="user?.socials?.linkedin_url"
             target="_blank">
            <img src="assets/icons/socials/linkedin.svg" alt="linkedin-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="user?.socials?.youtube_url"
             [href]="user?.socials?.youtube_url"
             target="_blank">
            <img src="assets/icons/socials/youtube.svg" alt="youtube-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="user?.socials?.zillow_url"
             [href]="user?.socials?.zillow_url"
             target="_blank">
            <img src="assets/icons/socials/zillow.svg" alt="zillow-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="user?.socials?.realtor_url"
             [href]="user?.socials?.realtor_url"
             target="_blank">
            <img src="assets/icons/socials/realtor.svg" alt="realtor-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="user?.socials?.personal_website_url"
             [href]="user?.socials?.personal_website_url"
             target="_blank">
            <img src="assets/icons/socials/website.svg" alt="website-link"/>
          </a>
        </div>
      </div>
    </div>
    <div class="agent" *ngIf="partner">
      <div class="agent-avatar">
        <avatar-placeholder class="s-132" [image]="partner.avatar.medium"></avatar-placeholder>
      </div>
      <div class="agent-info">
        <h4 class="agent-name">{{ partner.name }}</h4>
        <div class="agent-position m-b-4">
          <span class="agent-role">{{ partner.job_title || partner.role }} • </span>
          <span class="agent-license">LIC# {{ partner.license_number }}</span>
        </div>
        <div class="agent-position m-b-16" *ngIf="partner.company_license_number">
          <span class="agent-company">{{ partner.company_name }} • </span>
          <span class="agent-license">LIC# {{ partner.company_license_number }}</span>
        </div>
        <div class="agent-contacts">
          <a class="agent-contacts__item agent-contacts__item--phone text-overflow"
             [href]="'tel:' + partner.phone"
             [title]="partner.phone"
             (click)="track(EVENT_ACTIONS.CLICK_PHONE_LINK)">
            {{ partner.phone | phone }}
          </a>
          <a class="agent-contacts__item agent-contacts__item--email text-overflow"
             [href]="'mailto:' + partner.email"
             [title]="partner.email"
             (click)="track(EVENT_ACTIONS.CLICK_EMAIL_LINK)">
            {{ partner.email }}
          </a>
        </div>
        <div class="socials__list" *ngIf="partner?.socials">
          <a class="socials__list--link"
             *ngIf="partner?.socials?.facebook_url"
             [href]="partner?.socials?.facebook_url"
             target="_blank">
            <img src="assets/icons/socials/facebook.svg" alt="facebook-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="partner?.socials?.instagram_url"
             [href]="partner?.socials?.instagram_url"
             target="_blank">
            <img src="assets/icons/socials/instagram.svg" alt="instagram-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="partner?.socials?.linkedin_url"
             [href]="partner?.socials?.linkedin_url"
             target="_blank">
            <img src="assets/icons/socials/linkedin.svg" alt="linkedin-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="partner?.socials?.youtube_url"
             [href]="partner?.socials?.youtube_url"
             target="_blank">
            <img src="assets/icons/socials/youtube.svg" alt="youtube-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="partner?.socials?.zillow_url"
             [href]="partner?.socials?.zillow_url"
             target="_blank">
            <img src="assets/icons/socials/zillow.svg" alt="zillow-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="partner?.socials?.realtor_url"
             [href]="partner?.socials?.realtor_url"
             target="_blank">
            <img src="assets/icons/socials/realtor.svg" alt="realtor-link"/>
          </a>
          <a class="socials__list--link"
             *ngIf="partner?.socials?.personal_website_url"
             [href]="partner?.socials?.personal_website_url"
             target="_blank">
            <img src="assets/icons/socials/website.svg" alt="website-link"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="disclaimer__wrapper">
  <div class="disclaimer">
    <p class="disclaimer-text m-b-16"
       *ngIf="user.company_disclaimer_text"
       [innerHtml]="user.company_disclaimer_text"></p>
    <p class="disclaimer-text" [innerHtml]="user.disclaimer_text"></p>
    <div class="specialist-icons text-center">
      <img class="disclaimer-icon m-t-40"
           *ngIf="user?.disclaimer_logo !== 0"
           [src]="'assets/images/commons/disclaimer/' + (user?.disclaimer_logo || 1) + '.svg'"/>
    </div>
  </div>
</div>
