<div class="apply">
  <div class="apply-preview">
    <img [src]="previewImage"/>
  </div>
  <div class="apply-content text-center">
    <h2 class="apply-title">Can I qualify for a loan?</h2>
    <p class="apply-caption text--lg">When applying for a loan, lenders will evaluate your debt / income ratio (DTI) and
      credit score in order to determine whether you can afford to take on another payment.</p>
    <h3 class="apply-subtitle">Loan program DTI requirement:</h3>
    <div class="apply-actions">
      <div class="btn-group">
        <ng-container *ngFor="let item of DTI_DATA">
          <button class="btn"
                  type="button"
                  [class.btn--default]="item.active"
                  (click)="setDtiProgram(item)">{{ item.id }}</button>
        </ng-container>
      </div>
    </div>
    <h3 class="apply-subtitle m-b-8">{{ currentTab.title }}</h3>
    <p class="apply-caption apply-caption--bottom text--lg">{{ currentTab.text }}</p>
    <a *ngIf="username else previewMode"
       class="apply-btn"
       [routerLink]="stepperLink?.link"
       [queryParams]="stepperLink?.query_params">
      <div class="btn">Get Started</div>
    </a>
    <ng-template #previewMode>
      <preview-button>
        <button class="apply-btn">
          <div class="btn">Get Started</div>
        </button>
      </preview-button>
    </ng-template>
  </div>
</div>
