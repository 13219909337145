/**
 * Redirects can be LOCAL (RELATIVE) and ABSOLUTE.
 * LOCAL redirects replace a single URL segment with a different one.
 *
 * Example:
 * Current url: "*.domain/parent/child"
 * this.router.navigate(['other_child'])
 * Current url will be "*.domain/parent/other_child"
 *
 * Also RELATIVE redirects:
 * this.router.navigate(['some_route]);
 * this.router.navigate(['./some_route]); - note the leading dot sign
 * this.router.navigate(['./]);
 * this.router.navigate(['../]);
 *
 * ABSOLUTE redirects replace the whole URL. If the redirectTo value starts with a /, then it is an ABSOLUTE redirect.
 *
 * Example:
 * Current url: "*.domain/parent/child"
 * this.router.navigate(['/some_other_route'])
 * Current url will be "*.domain/some_other_route"
 *
 * Also ABSOLUTE redirects:
 * this.router.navigate(['/some_route]);
 * this.router.navigate(['/some_route/some_child]);
 *
 * The router supports directory-like syntax in a link parameters list to help guide route name lookup:
 * ./ or NO leading slash is RELATIVE to the current level.
 * ../ to go up one level in the route path.
 * You can combine RELATIVE navigation syntax with an ancestor path. If you must navigate to a sibling route,
 * you could use the ../<sibling> convention to go up one level, then over and down the sibling route path.
 *
 */
import type { Dictionary } from 'asap-team/asap-tools';

type RouteConstant = {
  EMPTY: string;
  ANY: string;
  name: Dictionary<string>;
  alias: Dictionary<string>;
};

const ANY: string = '**';
const CERTIFICATE: string = 'certificate';
const EMPTY: string = '';
const HOME: string = 'home';
const PROGRAM: string = 'program';
const STEPPER: string = 'stepper';
const NOT_FOUND: string = 'not-found';
const EXAMPLE: string = 'example';

export const ROUTE: RouteConstant = {
  EMPTY,
  ANY,
  name: {
    CERTIFICATE,
    EXAMPLE,
    HOME,
    PROGRAM,
    STEPPER,
    NOT_FOUND,
  },
  alias: {
    // single
    HOME: `/${HOME}`,
    STEPPER: `/${STEPPER}`,
    NOT_FOUND: `/${NOT_FOUND}`,
    // compounded
    CERTIFICATE: `/${STEPPER}/${CERTIFICATE}`,
  },
};
