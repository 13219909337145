import { Injectable } from '@angular/core';
import { PROGRAMS_CONFIG } from '@consts/programs-config';
import { Program, Programs, ProgramsPermissions } from '@core/types';
import { find, forEach, filter } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ProgramsService {

  private dividePrograms(programs: Program[]): Programs {
    return {
      company: filter(programs, (item: Program) => item.type === 'company'),
      industry: filter(programs, (item: Program) => item.type === 'industry'),
    };
  }

  getPermitted(permissions: ProgramsPermissions): Programs {
    let permittedPrograms: Program[] = [];

    forEach(permissions, (value: boolean, key: string) => {
      if (value) {
        const program: Program = find(PROGRAMS_CONFIG, (currentProgram: Program) => currentProgram.name === key);

        if (program) {
          permittedPrograms = [...permittedPrograms, program];
        }
      }
    });

    return this.dividePrograms(permittedPrograms);
  }

  getAll(): Programs {
    return this.dividePrograms(PROGRAMS_CONFIG);
  }

}
