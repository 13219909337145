import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import {
  AsapCommonModule,
  SentinelErrorHandler,
  SENTINEL_CONFIG,
  API_INTECEPTOR_CONFIG,
  APIInterceptor,
  JSONApiDeserializerInterceptor,
} from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';

// Components
import { RootComponent } from '@modules/root/root.component';
import { ExampleComponent } from '@modules/root/example/example.component';

// Services
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';

// Modules
import { AppCommonModule } from '@commons/commons.module';
import { AppRoutingModule } from '@modules/routes/app-routing.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AsapCommonModule,
    AppCommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  declarations: [
    RootComponent,
    ExampleComponent,
  ],
  providers: [
    {
      provide: API_INTECEPTOR_CONFIG,
      useValue: {
        api: environment.api,
        bypass: [
          'assets',
          'v2',
          'tracking',
        ],
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JSONApiDeserializerInterceptor,
      multi: true,
    },
    {
      provide: SENTINEL_CONFIG,
      useValue: { environment, logErrors: true },
    },
    {
      provide: ErrorHandler,
      useClass: SentinelErrorHandler,
    },
    TrackingScriptsService,
  ],
  bootstrap: [RootComponent],
})
export class RootModule {}
