export const environment: any = {
  name: 'dev',
  production: true,
  sentryDSN: 'https://5dd86fc0fc214c06a22df0115a43fe3b@o325577.ingest.sentry.io/5197104',
  api: 'https://api.dev.myhomeiq.report/api',
  reportLandingUrl: 'https://dev.homeiqreport.com/lender/',
  sentryWhitelist: [
    'http://dev.buyerprequalify.com',
    'https://dev.buyerprequalify.com',
  ],
};
