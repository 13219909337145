import type { StepTrackingEvent } from '@core/types';

import { CODE_STEPS } from './consts';

export const DEFAULT_EVENT_CATEGORY: string = 'BuyerIQ Stepper';

export const EVENT_ACTIONS: Record<string, string> = {
  VIEW_LANDING_PAGE: 'view_landing_page',
  VIEW_ZIP_CODE_STEP: 'view_zip_code_step',
  VIEW_FIRST_TIME_BUYER_STEP: 'view_first_time_buyer_step',
  VIEW_TYPE_OF_PROPERTY_STEP: 'view_type_of_property_step',
  VIEW_CREDIT_SCORE_STEP: 'view_credit_score_step',
  VIEW_PURCHASE_PROGRESS_STEP: 'view_purchase_progress_step',
  VIEW_HOW_PROPERTY_BE_USED_STEP: 'view_how_property_be_used_step',
  VIEW_AMOUNT_FOR_QUALIFICATION_STEP: 'view_amount_for_qualification_step',
  VIEW_DOWNPAYMENT_STEP: 'view_downpayment_step',
  VIEW_ANNUAL_INCOME_STEP: 'view_annual_income_step',
  VIEW_EMPLOYMENT_STEP: 'view_employment_step',
  VIEW_MONTHLY_SPENDINGS_STEP: 'view_monthly_spendings_step',
  VIEW_NAME_STEP: 'view_name_step',
  VIEW_EMAIL_STEP: 'view_email_step',
  VIEW_PHONE_STEP: 'view_phone_step',
  VIEW_RESULT_SUCCESS: 'view_result_success',
  VIEW_RESULT_ERROR: 'view_result_error',
  VIEW_CONGRATULATIONS_PAGE: 'view_congratulations_page',
  DOWNLOAD_BUYERIQ_CERTIFICATE: 'download_buyeriq_certificate',
  CLICK_EMAIL_LINK: 'click_email_link',
  CLICK_PHONE_LINK: 'click_phone_link',
  CLICK_MESSENGER_LINK: 'click_messenger_link',
};

export const STEP_EVENTS: StepTrackingEvent[] = [
  {
    event: EVENT_ACTIONS.VIEW_ZIP_CODE_STEP,
    code: CODE_STEPS.zipCode,
  },
  {
    event: EVENT_ACTIONS.VIEW_FIRST_TIME_BUYER_STEP,
    code: CODE_STEPS.whichTimeBuyer,
  },
  {
    event: EVENT_ACTIONS.VIEW_TYPE_OF_PROPERTY_STEP,
    code: CODE_STEPS.propertyType,
  },
  {
    event: EVENT_ACTIONS.VIEW_CREDIT_SCORE_STEP,
    code: CODE_STEPS.creditScore,
  },
  {
    event: EVENT_ACTIONS.VIEW_PURCHASE_PROGRESS_STEP,
    code: CODE_STEPS.currentProperty,
  },
  {
    event: EVENT_ACTIONS.VIEW_HOW_PROPERTY_BE_USED_STEP,
    code: CODE_STEPS.propertyUsed,
  },
  {
    event: EVENT_ACTIONS.VIEW_AMOUNT_FOR_QUALIFICATION_STEP,
    code: CODE_STEPS.purchasePrice,
  },
  {
    event: EVENT_ACTIONS.VIEW_DOWNPAYMENT_STEP,
    code: CODE_STEPS.downPayment,
  },
  {
    event: EVENT_ACTIONS.VIEW_ANNUAL_INCOME_STEP,
    code: CODE_STEPS.annualIncome,
  },
  {
    event: EVENT_ACTIONS.VIEW_EMPLOYMENT_STEP,
    code: CODE_STEPS.employmentStatus,
  },
  {
    event: EVENT_ACTIONS.VIEW_MONTHLY_SPENDINGS_STEP,
    code: CODE_STEPS.monthlySpendings,
  },
  {
    event: EVENT_ACTIONS.VIEW_NAME_STEP,
    code: CODE_STEPS.fullName,
  },
  {
    event: EVENT_ACTIONS.VIEW_EMAIL_STEP,
    code: CODE_STEPS.email,
  },
  {
    event: EVENT_ACTIONS.VIEW_PHONE_STEP,
    code: CODE_STEPS.phone,
  },
];
