// Modules
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxMaskModule } from 'ngx-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AsapCommonModule } from 'asap-team/asap-tools';

// Components
import { SpecialistComponent } from '@commons/specialist/specialist.component';
import { AvatarPlaceholderComponent } from '@commons/avatar-placeholder/avatar-placeholder.component';
import { LogoComponent } from '@commons/logo/logo.component';
import { HeaderComponent } from '@commons/header/header.component';
import { NavbarComponent } from '@commons/navbar/navbar.component';
import { ProgramsComponent } from '@commons/programs/programs.component';
import { MortgageComponent } from '@commons/mortgage/mortgage.component';
import { ApplyComponent } from '@commons/apply/apply.component';
import { ProcessComponent } from '@commons/process/process.component';
import { QuestionsComponent } from '@commons/questions/questions.component';
import { EmptyProgramsComponent } from '@commons/empty-programs/empty-programs.component';
import { CertificateComponent } from '@commons/certificate/certificate.component';
import { FooterComponent } from '@commons/footer/footer.component';
import { PreviewButtonComponent } from '@commons/preview-button/preview-button.component';
import { PreviewModalComponent } from '@commons/preview-modal/preview-modal.component';

// Directives
import { AutofocusDirective } from '@commons/directives/autofocus.directive';

const shared: any[] = [
  AutofocusDirective,
  SpecialistComponent,
  AvatarPlaceholderComponent,
  LogoComponent,
  NavbarComponent,
  HeaderComponent,
  ProgramsComponent,
  MortgageComponent,
  ApplyComponent,
  ProcessComponent,
  QuestionsComponent,
  CertificateComponent,
  EmptyProgramsComponent,
  FooterComponent,
  PreviewButtonComponent,
  PreviewModalComponent,
];

const dialogs: any[] = [];

const imports: any[] = [
  Angular2PromiseButtonModule.forRoot({
    spinnerTpl: '<span class="btn-spinner"></span>',
    btnLoadingClass: 'is-loading',
  }),
  CommonModule,
  FormsModule,
  RouterModule,
  InlineSVGModule.forRoot({ baseUrl: '/assets/icons/' }),
  NgxMaskModule.forRoot(),
  ReactiveFormsModule,
  ClickOutsideModule,
  RxReactiveFormsModule,
  ToastrModule.forRoot({
    maxOpened: 1,
    autoDismiss: true,
    preventDuplicates: true,
    positionClass: 'toast-bottom-center',
    toastClass: 'notify',
  }),
  AsapCommonModule,
];

const exported: any[] = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  NgxMaskModule,
  InlineSVGModule,
  ReactiveFormsModule,
  ClickOutsideModule,
  RxReactiveFormsModule,
  Angular2PromiseButtonModule,
  SimpleModalModule.forRoot({ container: 'modal-container' }),
  AsapCommonModule,
];

const declarations: any[] = [];

@NgModule({
  imports,
  exports: [
    ...exported,
    ...dialogs,
    ...shared,
  ],
  declarations: [
    ...declarations,
    ...dialogs,
    ...shared,
  ],
  providers: [
    CurrencyPipe,
  ],
})

export class AppCommonModule {}
