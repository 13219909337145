import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Consts
import { ROUTE } from '@consts/routes';

// Resolvers
import { HomeResolve } from '@core/resolvers/home/home.resolve';
import { UserResolve } from '@core/resolvers/user/user.resolve';

// Components
import { RootComponent } from '@modules/root/root.component';
import { ExampleComponent } from '@modules/root/example/example.component';

// Routes
import { STEPPER } from './stepper.router';
import { HOME_PREVIEW } from './home-preview.route';
import { HOME } from './home.route';

const routes: Routes = [
  {
    path: ROUTE.EMPTY,
    pathMatch: 'full',
    resolve: { home: HomeResolve },
    component: RootComponent,
  },
  {
    path: ROUTE.name.EXAMPLE,
    component: ExampleComponent,
  },
  {
    path: ROUTE.name.PROGRAM,
    component: RootComponent,
    children: [
      HOME_PREVIEW,
    ],
  },
  {
    path: ':username',
    component: RootComponent,
    resolve: { data: UserResolve },
    children: [
      STEPPER,
      HOME,
    ],
  },
  { path: ROUTE.ANY, redirectTo: ROUTE.EMPTY },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    paramsInheritanceStrategy: 'always',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
