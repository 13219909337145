<section class="header" [ngClass]="{ 'default': !program, 'custom-bg': defaultPreview }" [ngStyle]="backgroundImage">
  <div class="header-container">
    <div>
      <h1 class="header-title">{{ programTitle }}</h1>
      <p class="header-caption text--lg">A quick & easy tool to find out what you may qualify for!</p>
      <button class="apply-btn btn btn--default"
              *ngIf="stepperLink; else previewMode"
              type="button"
              [routerLink]="stepperLink?.link"
              [queryParams]="stepperLink.query_params">
        Get Started
      </button>
      <ng-template #previewMode>
        <preview-button>
          <button class="apply-btn btn btn--default" type="button">Get Started</button>
        </preview-button>
      </ng-template>
    </div>
  </div>
</section>
