<section class="certificate text-center">
  <h2 class="certificate-title">Receive your pre-qualification certificate</h2>
  <p class="certificate-text text">A quick & easy tools to find out what you may qualify for!</p>
  <a *ngIf="stepperLink else previewMode"
     class="apply-btn"
     [routerLink]="stepperLink?.link"
     [queryParams]="stepperLink?.query_params">
    <div class="btn btn--white">Get Started</div>
  </a>
  <ng-template #previewMode>
    <preview-button>
      <button class="apply-btn">
        <div class="btn btn--white">Get Started</div>
      </button>
    </preview-button>
  </ng-template>
</section>
