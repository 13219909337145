import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LogoComponent {

  @Input() logo: string;

}
