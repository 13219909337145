<div class="m-l-15">
  <div class="flex-wrap">
    <div class="theme-block"></div>
    <div class="theme-block shade"></div>
    <div class="theme-block tint1"></div>
    <div class="theme-block tint2"></div>
    <div class="theme-block tint3"></div>
  </div>
  <h1 class="m-b-24">Heading 1</h1>
  <h2 class="m-b-24">Heading 2</h2>
  <h3 class="m-b-24">Heading 3</h3>
  <h4 class="m-b-24">Heading 4</h4>
  <h5 class="m-b-24">Heading 5</h5><br/>
  <p class="text m-b-16">Plain text 16px</p>
  <p class="text--lg m-b-16">Plain text 18px</p><br/>
  <p class="cursive-highlight m-b-16">Cursive highlight</p><br/>
  <p class="caption caption-italic m-b-16">Caption cursive</p>
  <p class="caption m-b-16">Caption 14px</p><br/>
  <p class="disclaimer-text m-b-16">Disclaimer: All products are not available in all states. All options<br/>are not
    available on all programs.</p><br/>
  <button class="btn btn--default m-r-15 m-b-24" type="button">Default</button>
  <button class="btn btn--white m-r-15 m-b-24" type="button">Default</button>
  <button class="btn btn--grey m-r-15 m-b-24" type="button">Default</button>
  <br/>
  <button class="btn btn--default m-r-15 m-b-24" type="button" disabled="disabled">Default</button>
  <button class="btn btn--white m-r-15 m-b-24" type="button" disabled="disabled">Default</button>
  <button class="btn btn--grey m-r-15 m-b-24" type="button" disabled="disabled">Default</button>
  <br/>
  <div class="btn-group">
    <button class="btn btn--default" type="button">Default</button>
    <button class="btn btn--default" type="button">Default</button>
    <button class="btn btn--default" type="button" disabled="disabled">Disabled</button>
  </div>
  <br/>
  <form class="form-group">
    <div class="input-group">
      <div class="icon" [inlineSVG]="'symbol-defs.svg#icon-full_name'"></div>
      <input class="form-control m-b-16" type="text" name="name" value="" placeholder="Placeholder"/>
    </div>
    <div class="input-group">
      <div class="icon" [inlineSVG]="'symbol-defs.svg#icon-full_name'"></div>
      <input class="form-control" type="text" name="name" value="" placeholder="Placeholder" disabled="disabled"/>
    </div>
  </form>
</div>
