import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Sentinel } from 'asap-team/asap-tools';
import { RootModule } from './app/modules/root/root.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const sentinel: Sentinel = new Sentinel(environment);

sentinel.onguard();

platformBrowserDynamic()
  .bootstrapModule(RootModule)
  .catch((err: any) => console.error(err));
