import { Component, Input } from '@angular/core';

import type { HomeStepperLink } from '@core/types';

@Component({
  selector: 'home-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.sass'],
})
export class CertificateComponent {

  @Input() stepperLink: HomeStepperLink;

}
