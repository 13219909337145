<section class="empty text-center">
  <h2 class="empty-title">Your mortgage approval starts here.</h2>
  <p class="empty-caption text--lg">
    A new home or mortgage savings could be just a few screens away. Get started now – no commitment involved.
  </p>
  <div class="empty-container grid">
    <div class="empty-row grid-row">
      <div class="empty-item grid-col">
        <img class="empty-item__icon" src="/assets/icons/empty-programs/1.svg"/>
        <h4 class="empty-item__label">Instant Pre-Qualification</h4>
      </div>
      <div class="empty-item grid-col">
        <img class="empty-item__icon" src="/assets/icons/empty-programs/2.svg"/>
        <h4 class="empty-item__label">Personal mortgage expert</h4>
      </div>
      <div class="empty-item grid-col">
        <img class="empty-item__icon" src="/assets/icons/empty-programs/3.svg"/>
        <h4 class="empty-item__label">Something else</h4>
      </div>
    </div>
  </div>
</section>
