import { Injectable, Inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class HomeResolve implements Resolve<any> {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  resolve(): boolean {
    this.document.location.href = `${environment.reportLandingUrl}`;

    return false;
  }

}
