<div class="questions">
  <div class="questions-container grid">
    <h2 class="questions-title text-center">Frequently asked questions</h2>
    <div class="questions-row grid-row">
      <div class="questions-item grid-col">
        <h4 class="questions-item__title">What is a pre-qualify?</h4>
        <p class="questions-item__text text">The first step in the home-buying process is to get pre-approved, which is
          a statement of how much money the lender is willing to let you borrow to pay for a home.</p>
      </div>
      <div class="questions-item grid-col">
        <h4 class="questions-item__title">Do I need to get a loan before I find a home?</h4>
        <p class="questions-item__text text">By going through the pre-qualify process you will know how much money a
          lender is willing to give you – saving time during your house search by eliminating the homes out of your
          price range.</p>
      </div>
      <div class="questions-item grid-col">
        <h4 class="questions-item__title">What’s required to get pre-approved?</h4>
        <p class="questions-item__text text">To get pre-approved for a home loan, you will need to provide the lender
          your financial information such as your income, debt, and undergo a credit check.</p>
      </div>
      <div class="questions-item grid-col">
        <h4 class="questions-item__title">How can I find out my credit score?</h4>
        <p class="questions-item__text text">Once a year, you are entitled to a free copy of your credit score from all
          the major credit bureaus. To obtain your free credit report visit www.annualcreditreport.com</p>
      </div>
      <div class="questions-item grid-col">
        <h4 class="questions-item__title">Why should I get pre-approved now?</h4>
        <p class="questions-item__text text">If you are searching for homes, sellers want to know that a lender has
          already vetted you and will lend you the money. If you find a home you like, you will not be able to submit an
          offer without a pre-qualify.</p>
      </div>
      <div class="questions-item grid-col">
        <h4 class="questions-item__title">How quickly can I get pre-approved?</h4>
        <p class="questions-item__text text">Getting pre-approved is a quick process – once you fill out a loan
          application the lender will need to run your credit to determine what amount and rate you can qualify for.</p>
      </div>
    </div>
  </div>
</div>
