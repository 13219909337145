import { Component, Input } from '@angular/core';
import { ROUTE } from '@consts/routes';

import type { Program, HomeStepperLink } from '@core/types';

@Component({
  selector: 'home-mortgage',
  templateUrl: './mortgage.component.html',
  styleUrls: ['./mortgage.component.sass'],
})
export class MortgageComponent {

  @Input() username: string;

  @Input() companyName: string;

  @Input() programs: Program[];

  @Input() stepperLink: HomeStepperLink;

  getProgramLink(programName: string): string {
    return this.username ? `/${this.username}/${programName}` : `/${ROUTE.name.PROGRAM}/${programName}`;
  }

}
