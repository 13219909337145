import { EMPTY, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { ThemeService } from 'asap-team/asap-tools';

import type { UserResponse } from '@core/types';

// Consts
import { environment } from 'environments/environment';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class UserResolve implements Resolve<UserResponse> {

  constructor(
    private userService: UserService,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: any,
  ) {}

  resolve({ params }: ActivatedRouteSnapshot): Observable<UserResponse | never> {
    const { username } = params;

    return this
      .userService
      .getUser(username)
      .pipe(
        tap((response: UserResponse) => {
          this.themeService.setTheme(response?.user?.settings?.theme || 'flamingo');
        }),
        catchError(() => {
          this.document.location.href = `${environment.reportLandingUrl}`;

          return EMPTY;
        }),
      );
  }

}
