import { Component, OnInit, Input } from '@angular/core';

import type { User } from '@core/types';

// Consts
import { EVENT_ACTIONS } from '@consts/analytics-events';

// Services
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';

@Component({
  selector: 'home-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit {

  @Input() user: User;

  @Input() username: string;

  isOpen: boolean = false;

  EVENT_ACTIONS: Record<string, string> = EVENT_ACTIONS;

  constructor(
    private trackingScriptsService: TrackingScriptsService,
  ) {}

  ngOnInit(): void {}

  get rootRoute(): string {
    return `/${this.username}`;
  }

  toggleNav(): void {
    this.isOpen = !this.isOpen;
  }

  onClickedOutside(): void {
    this.isOpen = false;
  }

  getMessengerUrl(username: string): string {
    if (!username) { return ''; }

    return `https://www.facebook.com/messages/t/${username}`;
  }

  track(event: string): void {
    if (!event) { return; }

    this.trackingScriptsService.send(event);
  }

}
