import { Component, HostListener } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';

import { PreviewModalComponent } from '@commons/preview-modal/preview-modal.component';

@Component({
  selector: 'preview-button',
  template: '<ng-content></ng-content>',
})
export class PreviewButtonComponent {

  @HostListener('click') onClick(): void {
    this.showPreviewModal();
  }

  constructor(
    private simpleModalService: SimpleModalService,
  ) {}

  showPreviewModal(): void {
    this.simpleModalService.addModal(PreviewModalComponent);
  }

}
