<nav class="navbar flex-wrap">
  <a class="navbar-logo" [routerLink]="rootRoute" queryParamsHandling="preserve">
    <app-logo [logo]="user?.settings?.logo"></app-logo>
  </a>
  <div class="navbar-actions" *ngIf="user" [class.open]="isOpen" (clickOutside)="onClickedOutside()">
    <div class="navbar-toggle flex-wrap flex-center" (click)="toggleNav()">
      <div class="navbar-toggle__info text-right">
        <p class="agent-name">{{ user.name }}</p>
        <p class="agent-caption">Talk to a mortgage professional</p>
        <h4 class="agent-phone">{{ user.phone | phone }}</h4>
      </div>
      <div class="navbar-toggle__agent flex-wrap flex-center">
        <avatar-placeholder [image]="user.avatar.small"></avatar-placeholder>
        <div class="navbar-toggle__close flex-wrap flex-center" [inlineSVG]="'symbol-defs.svg#icon-close'"></div>
      </div>
    </div>
    <div class="navbar-dropdown text-center">
      <avatar-placeholder class="s-96" [image]="user.avatar.medium"></avatar-placeholder>
      
      <h4 class="navbar-dropdown__name text-overflow" [title]="user.name">
        {{ user.name }}
      </h4>
      
      <div class="flex-wrap flex-center m-b-16">
        <span class="agent-role">{{ user.job_title || user.role }}</span>
        <i class="point-divider"></i>
        <span class="agent-license">{{ user.license_number }}</span>
      </div>
      
      <div class="agent-contacts">
        <a class="agent-contacts__item agent-contacts__item--email text-overflow"
           [href]="'mailto:' + user.email"
           [title]="user.email"
           (click)="track(EVENT_ACTIONS.CLICK_EMAIL_LINK)">{{ user.email }}</a>
      </div>
      
      <div class="agent-contacts m-b-16">
        <a class="agent-contacts__item agent-contacts__item--phone text-overflow"
           [href]="'tel:' + user.phone"
           [title]="user.phone"
           (click)="track(EVENT_ACTIONS.CLICK_PHONE_LINK)">{{ user.phone | phone }}</a>
      </div>
      
      <div class="socials__list" *ngIf="user?.socials">
        <a class="socials__list--link"
           *ngIf="user?.socials?.facebook_url"
           [href]="user?.socials?.facebook_url"
           target="_blank">
          <img src="assets/icons/socials/facebook.svg" alt="facebook-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="user?.socials?.instagram_url"
           [href]="user?.socials?.instagram_url"
           target="_blank">
          <img src="assets/icons/socials/instagram.svg" alt="instagram-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="user?.socials?.linkedin_url"
           [href]="user?.socials?.linkedin_url"
           target="_blank">
          <img src="assets/icons/socials/linkedin.svg" alt="linkedin-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="user?.socials?.youtube_url"
           [href]="user?.socials?.youtube_url"
           target="_blank">
          <img src="assets/icons/socials/youtube.svg" alt="youtube-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="user?.socials?.zillow_url"
           [href]="user?.socials?.zillow_url"
           target="_blank">
          <img src="assets/icons/socials/zillow.svg" alt="zillow-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="user?.socials?.realtor_url"
           [href]="user?.socials?.realtor_url"
           target="_blank">
          <img src="assets/icons/socials/realtor.svg" alt="realtor-link"/>
        </a>
        <a class="socials__list--link"
           *ngIf="user?.socials?.personal_website_url"
           [href]="user?.socials?.personal_website_url"
           target="_blank">
          <img src="assets/icons/socials/website.svg" alt="website-link"/>
        </a>
      </div>
      
      <a *ngIf="user.fb_messenger"
         target="_blank"
         [href]="getMessengerUrl(user.fb_messenger)"
         (click)="track(EVENT_ACTIONS.CLICK_MESSENGER_LINK)">
        <button class="btn btn--default" type="button">Chat</button>
      </a>
    </div>
  </div>
</nav>
