import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import type { User, UserResponse } from '@core/types';

// Consts
import { EVENT_ACTIONS } from '@consts/analytics-events';

// Services
import { UserService } from '@core/services/user/user.service';
import { TrackingScriptsService } from '@core/helpers/tracking-scripts/tracking-scripts.service';

@Component({
  selector: 'home-specialist',
  templateUrl: './specialist.component.html',
  styleUrls: ['./specialist.component.sass'],
})
export class SpecialistComponent implements OnInit {

  @Input() user: User;

  partner: User;

  EVENT_ACTIONS: Record<string, string> = EVENT_ACTIONS;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private trackingScriptsService: TrackingScriptsService,
  ) {}

  ngOnInit(): void {
    const { partner } = this.route.snapshot.queryParams;
    const { username } = this.route.snapshot.params;

    if (username && partner) {
      this.getPartnerData(username, partner);
    }
  }

  getPartnerData(username: string, partner: string): void {
    this
      .userService
      .getUser(username, partner)
      .subscribe(
        ({ user }: UserResponse) => {
          this.partner = user.partner;
        },
      );
  }

  track(event: string): void {
    if (!event) { return; }

    this.trackingScriptsService.send(event);
  }

}
